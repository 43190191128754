import React, { useState } from "react";
import HomeBanner from "./home-bannar";
import HomeShareBox from "./home-share-box";
import HomeSelection from "./home-selection";
import EventDetail from "./event-details";
import EventAttendees from "./event-attendees";
import Footer from "../Footer";
import "../../App.css";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Title from "../../components/title";
import { fonts } from "../../theme";
export default function Index() {
  const [selected, setSelected] = useState("Detail");

  const openSubmission = () => {
    window.location.href = "/register";
  };
  const websiteRedirect = () => {
    window.location.href = "https://events.joingather.app/";
  };

  return (
    <>
      <HomeBanner />
      <HomeShareBox />
      {/* <HomeSelection selected={selected} setSelected={setSelected} /> */}
      {selected === "Detail" ? <EventDetail /> : <EventAttendees />}
      <Footer />
      <div
        className={"join-box"}
        style={{
          width: isMobile ? "100%" : "30%",
          left: isMobile ? "0%" : "35%",
          borderRadius: isMobile ? "0px" : "10px",
        }}
      >
        <div className={"join-box-header"}>
          <div>
            <Title
              text={`You're Invited!`}
              color={"#000000"}
              fontFamily={fonts.ROBOTO.MEDIUM}
              fontSize={"22px"}
            />
            <span className={"are-you-joining"}>
              Find events like these and many more on Gather.
            </span>
          </div>
        </div>
        <div className={"join-box-body"}>
        <div
            className="join-box-btn"
            style={{
              backgroundColor: "rgb(255, 134, 69)",
            }}
            onClick={() => websiteRedirect()}
          >
            <Title
              text={`Join Today!`}
              color={"rgb(255, 255, 255)"}
              fontFamily={fonts.ROBOTO.BOLD}
              fontSize={"16px"}
            />
          </div>
          {/* <div
            className="join-box-btn"
            style={{
              backgroundColor: "rgb(210, 251, 208)",
            }}
            onClick={() => openSubmission()}
          >
            <Title
              text={`Going`}
              color={"rgb(5, 88, 67)"}
              fontFamily={fonts.ROBOTO.BOLD}
              fontSize={"16px"}
            />
          </div>
          <div
            className="join-box-btn"
            style={{
              backgroundColor: "rgb(255, 243, 213)",
            }}
            onClick={() => openSubmission()}
          >
            <Title
              text={`Maybe`}
              color={"rgb(88, 83, 110)"}
              fontFamily={fonts.ROBOTO.BOLD}
              fontSize={"16px"}
            />
          </div>
          <div
            className="join-box-btn"
            style={{
              backgroundColor: "rgb(254, 227, 212)",
            }}
            onClick={() => openSubmission()}
          >
            <Title
              text={`Can't Go`}
              color={"rgb(117, 8, 46)"}
              fontFamily={fonts.ROBOTO.BOLD}
              fontSize={"16px"}
            />
          </div> */}
        </div>
      </div>
    </>
  );
}
